/**
 * The JavaScript code you place here will be processed by esbuild, and the
 * output file will be created at `../theme/js/script.min.js` and enqueued in
 * `../theme/functions.php`.
 *
 * For esbuild documentation, please see:
 * https://esbuild.github.io/
 * ajax.url is available as a global variable
 */

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import liveSearch from './custom/live-search';
import newsGrid from './custom/news-grid';
import statBlock from './custom/stat-block';

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(intersect);

window.liveSearch = liveSearch;
window.newsGrid = newsGrid;
window.statBlock = statBlock;

Alpine.directive('stat-animate', (el, { expression }, { effect }) => {
	let target = Number(expression);
	if (!isNaN(target)) {
		let startTimestamp = null;

		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const elapsed = timestamp - startTimestamp;
			const progress = Math.min(elapsed / 3000, 1);

			el.textContent = (progress * target).toLocaleString('en-US', {
				maximumFractionDigits: 0,
			});

			if (progress < 1) {
				window.requestAnimationFrame(step);
			} else {
				el.textContent = target.toLocaleString('en-US');
			}
		};

		window.requestAnimationFrame(step);
	} else {
		el.classList.add('translate-y-3', 'transition-all', 'duration-500');
		setTimeout(function () {
			el.classList.remove('translate-y-3');
			el.classList.add('translate-y-0');
		}, 0);
	}
});

Alpine.store('search', {
	query: '',
	isOpen: false,
});

const parallaxElements = document.querySelectorAll('[data-parallax]');

window.addEventListener('scroll', () => {
	// get the window size, we dont want this on mobile
	const width = window.innerWidth;
	if (width < 1024) {
		return;
	}
	const y = window.scrollY;
	parallaxElements.forEach((el) => {
		const top = el.offsetTop;
		el.style.transform = `translateY(-${y > top ? y / 5 : 0}px)`;
	});
});

// create an alpine store that has the window width and height and recalculate it on resize
Alpine.store('window', {
	width: window.innerWidth,
	height: window.innerHeight,
});

window.addEventListener('resize', () => {
	Alpine.store('window', {
		width: window.innerWidth,
		height: window.innerHeight,
	});
});
