function liveSearch() {
	let debounceTimer;

	return {
		search: '',
		results: [],
		ajaxUrl: null,
		init() {
			this.ajaxUrl = ajax.url;
		},
		searchPosts() {
			clearTimeout(debounceTimer);

			if (this.search.length < 3) {
				this.results = [];
				return;
			}

			debounceTimer = setTimeout(() => {
				const data = new FormData();
				data.append('action', 'live_search');
				data.append('search', this.search);

				fetch(this.ajaxUrl, {
					method: 'POST',
					body: data,
				})
					.then((response) => response.json())
					.then((response) => {
						this.results = response;
					});
			}, 300);
		},
	};
}

export default liveSearch;
