function statBlock() {
  return {
    elements: [],
    init() {
      this.elements = this.$el.querySelectorAll('.animated-stat-number');
    },
    animateNumbers() {
      for (let i = 0; i < this.elements.length; i++) {
        const element = this.elements[i];
        const target = Number(element.textContent);
  
        if (!isNaN(target)) {
          let startTimestamp = null;
  
          function step(timestamp) {
            if (! startTimestamp) {
              startTimestamp = timestamp;
            }
  
            const elapsed = timestamp - startTimestamp;
            const progress = Math.min(elapsed / 500, 1);
  
            element.textContent = Math.floor(progress * target);
  
            if (progress < 1) {
              window.requestAnimationFrame(step);
            } else {
              element.textContent = target;
            }
          }
  
          window.requestAnimationFrame(step);
        }
  
        else{
          element.classList.add('translate-y-3','transition-all','duration-500');
          setTimeout(function(){
            element.classList.remove('translate-y-3');
            element.classList.add('translate-y-0');
          },0);
        }
      }
    }
  }
}

export default statBlock;