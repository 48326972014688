function newsGrid() {
	return {
		posts: [],
		hasMore: false,
		page: 1,
		// comments are for lazy loading
		initialized: false,
		init() {
			this.loadMore();
			// this.initialized = true;
		},
		after() {
			return this.posts;
		  },
		async loadMore() {
			// if (!this.initialized) {
			// 	return;
			// }
			const data = new FormData();
			data.append('action', 'get_posts');
			data.append('page', this.page);
			try {
				const response = await fetch(ajax.url, {
					method: 'POST',
					body: data,
				});
				const json = await response.json();
				this.hasMore = json.has_more;
				this.page++;
				this.posts = [...this.posts, ...json.posts];
				this.initialized = true;
			} catch (error) {
				console.error(error);
			}
		},
	};
}

export default newsGrid;
